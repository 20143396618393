




















import Axios from 'axios';
import { BAlert, BIconExclamationTriangle } from 'bootstrap-vue';
import { DateTime } from 'luxon';
import Vue from 'vue';

import { Summary } from '@/models/summary';

export default Vue.extend({
  name: 'StatusEmbed',
  components: {
    BAlert,
    BIconExclamationTriangle,
  },
  props: {
    href: String,
    pageId: String,
    maxDays: {
      type: Number,
      default: 14,
      validator(value: number) {
        return value > 0;
      },
    },
  },
  computed: {
    storageId() {
      return 'wt-operational-status';
    },
  },
  data() {
    return {
      level: 'warning',
      content: '',
      title: '',
      pollingInterval: 60 * 1000,
      timerId: 0,
      visible: false,
      itemId: '',
      dismissedIds: [] as string[],
    };
  },
  created() {
    this.startPolling();
  },
  beforeDestroy() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  },
  methods: {
    getDismissedIds() {
      let ids: string[] = this.dismissedIds;
      try {
        const rawData = sessionStorage.getItem(`${this.storageId}-dismissed`);
        if (rawData) {
          ids = JSON.parse(rawData);
        }
      } catch (e) {
        console.error('Failed to read session storage.', e);
      }
      return ids;
    },
    getSummary() {
      const url = `https://${this.pageId}.statuspage.io/api/v2/summary.json`;
      return Axios.get<Summary>(url);
    },
    dismiss(id: string) {
      const ids: string[] = [...this.getDismissedIds()];
      if (!ids.includes(id)) {
        ids.push(id);
      }
      try {
        this.dismissedIds = ids;
        sessionStorage.setItem(`${this.storageId}-dismissed`, JSON.stringify(ids));
      } catch (e) {
        console.error('Failed to write session storage.', e);
      }
    },
    updateStatus: async function updateStatus() {
      this.content = '';
      this.title = '';

      try {
        const response = await this.getSummary();
        const summary = response.data;
        const incident = summary?.incidents.find((entry) => {
          const updateAt = DateTime.fromISO(entry.updated_at);
          return updateAt.diffNow('days').negate().days <= this.maxDays
            && entry.status !== 'resolved'
            && entry.status !== 'postmortem'
            && !this.getDismissedIds().includes(entry.id);
        });
        const maintenance = summary?.scheduled_maintenances.find((entry) => {
          const updateAt = DateTime.fromISO(entry.updated_at);
          return updateAt.diffNow('days').negate().days <= this.maxDays
            && (entry.status === 'scheduled' || entry.status === 'in_progress' || entry.status === 'verifying')
            && !this.getDismissedIds().includes(entry.id);
        });
        if (incident) {
          const lastUpdateTime = DateTime.fromISO(incident.updated_at).toFormat('d MMM yyyy HH:mm');
          this.title = incident.name;
          this.content = `Last updated: ${lastUpdateTime}`;
          this.level = 'warning';
          this.itemId = incident.id;
          this.visible = true;
        } else if (maintenance) {
          const startTime = DateTime.fromISO(maintenance.scheduled_for).toFormat('d MMM yyyy HH:mm');
          const endTime = DateTime.fromISO(maintenance.scheduled_until).toFormat('d MMM yyyy HH:mm');
          this.title = maintenance.name;
          this.content = `${startTime} - ${endTime}`;
          this.level = 'info';
          this.itemId = maintenance.id;
          this.visible = true;
        }
      } catch (error) {
        this.title = 'Failed to retrieve operational status';
        this.visible = true;
        console.log('Failed to retrieve operational status.', error);
      }
    },
    closeNotification() {
      this.visible = false;
      this.dismiss(this.itemId);
    },
    startPolling() {
      this.updateStatus();

      if (this.timerId) {
        clearInterval(this.timerId);
      }
      this.timerId = setInterval(() => this.updateStatus(), this.pollingInterval);
    },
  },
});
